import { Input } from "@components/Form/Input";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface Props {
  defaultFields?: any;
  defaultGroups?: string[];
}

export const EmailSubscriptionForm: FC<Props> = ({
  defaultFields,
  defaultGroups = [],
}) => {
  const [email, setEmail] = React.useState(null);

  // form
  const formMethods = useForm({
    defaultValues: {
      email: "",
      fields: defaultFields,
      groups: defaultGroups,
    },
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = formMethods;

  const submit = async (data) => {
    const response = await fetch(
      "/api/email-subscriptions/subscribe-email-to-groups",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseJson = await response.json();
    if (responseJson.status === "success") {
      setEmail(data.email);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {!email ? (
        <div className="flex flex-row space-x-1">
          <Input
            path="email"
            placeholder="Email"
            register={register}
            errors={errors}
          />
          <button
            className="px-2 py-3 text-xs font-medium text-black bg-gray-200 rounded hover:bg-gray-300"
            type="submit"
          >
            Subscribe
          </button>
        </div>
      ) : (
        <div className="">
          Thanks! Go check <span className="font-bold">{email}</span> to confirm your
          subscription.
        </div>
      )}
    </form>
  );
};

import Link from "next/link";
import { FC } from "react";

interface Props {
  relatedPages?: {
    path: string;
    pageTitle: string;
  }[];
}

export const RelatedPages: FC<Props> = ({ relatedPages }) => {
  return relatedPages?.length > 0 ? (
    <ul className="space-y-2">
      {relatedPages.map((relatedPage) => (
        <li
          key={relatedPage.path}
          className="list-disc ml-4 marker:text-gray-400"
        >
          <Link
            href={relatedPage.path}
            className="text-blue-600 hover:underline list-disc"
          >
            {relatedPage.pageTitle}
          </Link>
        </li>
      ))}
    </ul>
  ) : null;
};

import { env } from "process";

// ENV
export const currentEnv = process.env.NODE_ENV;
export const isProd = currentEnv === "production";

// -------------------------------------------------------------------------------------------
// ELASTIC SEARCH
const ES_JOB_POST_INDEX_VERSION = 1;
export const ES_JOB_POST_INDEX_CURRENT_NAME = `job-post-${ES_JOB_POST_INDEX_VERSION}-${env["ELASTIC_ENV_SCOPE"]}`;
export const ES_MAX_DISTANCE_IN_KM = 500;
export const ES_MAX_AGE_IN_DAYS = 60;
export const ES_DEFAULT_QUERY_SIZE = 40;
export const ES_MAX_QUERY_SIZE = 100;
export const ES_MAX_QUERY_OFFSET = 1000;

export { ES_DEFAULT_DISTANCE_IN_KM } from "./config.client";

// Batch indexing.
export const ES_BATCH_INDEXING_BATCH_SIZE = 50;
export const ES_BATCH_INDEXING_PAUSE_BETWEEN_BATCHES_IN_SECONDS = 2;

// -------------------------------------------------------------------------------------------
// SCAPER
export const SCRAPER_PARSE_MAX_ITEMS = 3;
export const SCRAPER_SIMILARITY_THRESHOLD_FOR_DUPLICATES = 0.98;

// -------------------------------------------------------------------------------------------
// ANALYTICS (internal)
export const ANALYTICS_INGESTION_INTERVAL_IN_MINUTES = 2;
export const ANALYTICS_NUMBER_OF_EVENTS_TO_INGEST_AT_ONCE = 50;

// -------------------------------------------------------------------------------------------
// CURRENCY
export const CURRENCY_UPDATE_EXCHANGE_RATES_INTERVAL_IN_MINUTES = 1440; // 1 day

// -------------------------------------------------------------------------------------------
// QUALITY (tag)
export { QUALITY_SCORE_THRESHOLD, TAG_SCORE_THRESHOLD } from "./config.client";

// -------------------------------------------------------------------------------------------
// TWITTER
export const TWITTER_JOB_POST_QUALITY_THRESHOLD = 60;
export const TWITTER_JOB_POST_MAX_AGE_IN_DAYS = 3;
export const TWITTER_JOB_POST_TWEET_TIMES = [
  `01:34`,
  `05:32`,
  `09:04`,
  `11:56`,
  `15:58`,
  `20:34`,
].sort(); // needs to be sorted from earliest to latest
export const TWITTER_JOB_POST_TWEET_GENERAL_TAGS = [
  "javascript",
  "developer",
  "jobs",
  "job",
  "recruiting",
  "career",
  "js",
];

// -------------------------------------------------------------------------------------------
// SITEMAP
export const SITEMAP_JOB_POST_APPROVED_AT_MAX_AGE_IN_DAYS = 45;
export const SITEMAP_JOB_POST_PRIORITY_DEFAULT = 0.5;
export const SITEMAP_JOB_POST_CHANGEFREQ_DEFAULT = "weekly";

// -------------------------------------------------------------------------------------------
// SUPABASAE
export const SUPABASE_STORAGE_PUBLIC_BUCKET = "public";
export const SUPABASE_STORAGE_PUBLIC_BUCKET_ORGANISATION_PATH = "organisation/";

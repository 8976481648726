import { FC } from "react";
import { useRouter } from "next/router";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { ES_DEFAULT_QUERY_SIZE, ES_MAX_QUERY_OFFSET } from "@config/config";

interface Props {
  totalHits: number;
  // from: number;
  size: number;
  currentPage: number;
}

export const JobPostsPagination: FC<Props> = ({
  totalHits,
  size = ES_DEFAULT_QUERY_SIZE,
  currentPage,
}) => {
  const router = useRouter();

  const MAX_SIDE_STEPS = 3; // max number of steps to the left or right of the current page

  const totalPages = Math.min(
    Math.ceil(totalHits / size),
    ES_MAX_QUERY_OFFSET / size + 1
  );
  const pages = [...Array.from(Array(totalPages + 1).keys())].splice(1); // creates 1-indexed array of page numbers

  const getPath = (pge: number) => {
    // Remove segment props so they don't show in URLSearchParams
    const queryWithoutSegments = {
      ...router.query,
    };
    delete queryWithoutSegments.slug1;
    delete queryWithoutSegments.slug2;
    delete queryWithoutSegments.slug3;
    // Add page to query
    // const query = { ...queryWithoutSegments, from: (page * size).toString() };
    const query = {
      ...queryWithoutSegments,
      page: pge.toString(),
    };
    // Remove page param if it's the first page
    if (pge === 1) {
      delete query.page;
    }
    // Get clean route without params
    const route = router.asPath.split("?")[0];
    // Compose new path
    const isQueryEmpty = Object.keys(query).length === 0;
    const path = isQueryEmpty
      ? route
      : `${route}?${new URLSearchParams(query).toString()}`;
    return path;
  };

  return totalPages > 1 ? (
    <div className="text-center">
      {currentPage - MAX_SIDE_STEPS >= 0 && (
        <a
          href={getPath(1)}
          title="First page"
          className="mr-1 px-1.5 py-2.5 text-xs uppercase hover:bg-gray-100 border border-transparent hover:border-gray-100 rounded"
        >
          <ChevronDoubleLeftIcon className="inline h-4 -mt-0.5" />
        </a>
      )}
      {/* {currentPage - 1 >= 1 && (
        <a
          href={getPath(currentPage - 1)}
          title="Previous page"
          className="mr-1 px-1.5 py-2.5 text-xs uppercase hover:bg-gray-100 border border-transparent hover:border-gray-100 rounded"
        >
          <ChevronLeftIcon className="inline h-4 -mt-0.5" />
        </a>
      )} */}
      <span className="">
        {pages
          .filter((i) => {
            return (
              i >= currentPage - MAX_SIDE_STEPS &&
              i <= currentPage + MAX_SIDE_STEPS
            );
          })
          .map((i) =>
            i === currentPage ? (
              <span
                className="px-4 py-2 text-sm font-semibold text-white bg-black border border-black rounded"
                key={i}
              >
                {i}
              </span>
            ) : (
              <a
                key={i}
                href={getPath(i)}
                className="px-4 py-2 text-sm font-semibold hover:bg-gray-100 border border-transparent hover:border-gray-100 rounded"
              >
                {i}
              </a>
            )
          )}
      </span>
      {/* {currentPage + 1 < totalPages && (
        <a
          href={getPath(currentPage + 1)}
          title="Next page"
          className="ml-1 px-1.5 py-2.5 text-xs uppercase hover:bg-gray-100 border border-transparent hover:border-gray-100 rounded"
        >
          <ChevronRightIcon className="inline h-4 -mt-0.5" />
        </a>
      )} */}
      {currentPage + MAX_SIDE_STEPS < totalPages && (
        <a
          href={getPath(totalPages)}
          title="Last page"
          className="ml-1 px-1.5 py-2.5 text-xs uppercase hover:bg-gray-100 border border-transparent hover:border-gray-100 rounded"
        >
          <ChevronDoubleRightIcon className="inline h-4 -mt-0.5" />
        </a>
      )}
    </div>
  ) : null;
};

// Label
export const defaultLabelCN = "block mb-1 text-sm font-medium text-gray-700";

// Input
export const defaultInputCN =
  "block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300 focus:ring-blue-500 focus:border-blue-500";
export const defaultInputErrorStateCN = "border-red-500 bg-red-50";

// InputWithAddon
export const defaultInputWithAddonCN =
  "flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 placeholder-gray-300 focus:ring-blue-500 focus:border-blue-500";
export const defaultAddonCN =
  "inline-flex px-3 items-center text-gray-500 sm:text-sm rounded-l-md border border-r-0 border-gray-300 bg-gray-50";

// Select
export const defaultSelectCN =
  "block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500";
export const defaultSelectErrorStateCN = "border-red-500 bg-red-50";

// Description
export const defaultDescriptionCN = "pt-1 px-2 text-xs text-gray-600";

// ErrorMessage
export const defaultErrorMessageCN = "pt-1 px-2 text-xs text-red-600 rounded-b";

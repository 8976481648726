import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";
import React, { useState, useEffect, ReactElement, Children } from "react";

type ActiveLinkProps = LinkProps & {
  children: string | ReactElement | (string | ReactElement)[];
  className?: string;
  activeClassName?: string;
};

export const ActiveLink = ({
  children,
  className,
  activeClassName,
  ...props
}: ActiveLinkProps) => {
  const { asPath, isReady } = useRouter();
  const [composedClassName, setComposedClassName] = useState(className);

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (props.as || props.href) as string,
        location.href
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;

      const newComposedClassName =
        linkPathname === activePathname
          ? `${className} ${activeClassName}`.trim()
          : className;

      if (newComposedClassName !== composedClassName) {
        setComposedClassName(newComposedClassName);
      }
    }
  }, [
    asPath,
    isReady,
    props.as,
    props.href,
    className,
    activeClassName,
    setComposedClassName,
    composedClassName,
  ]);

  return (
    <Link className={composedClassName} {...props}>
      {children}
    </Link>
  );
};

import React, { FC } from "react";
import {
  JobPost,
  Remote,
  Location,
  Compensation,
  Organisation,
} from "@prisma/client";
import { JobPostsList } from "./JobPostsResults/JobPostsList";
import { JobPostSearchParams } from "@services/elasticsearch/utils/getJobPostSearchParamsFromServerQuery";
import { JobPostsPagination } from "./JobPostsResults/JobPostsPagination";
import { AssignedTag, NewTag } from "types/tags";
import { timeSinceFormatter } from "@utils/formatters/timeSinceFormatter";
import { EmailSubscriptionForm } from "@components/EmailSubscriptionForm/EmailSubscriptionForm";
import { RelatedPages } from "../RelatedPages";

interface Props {
  initialJobPostSearchParams: JobPostSearchParams;
  initialJobPosts: (JobPost & {
    remote: Remote;
    locations: Location[];
    compensation: Compensation;
    sourcingOrganisation: Organisation;
    hiringOrganisation: Organisation;
    tags?: (AssignedTag & NewTag)[];
  })[];
  totalHits: number;
  initialNextCursor?: string | string[];
  relatedPages?: {
    path: string;
    pageTitle: string;
  }[];
}

export const JobPostsSearchAndResults: FC<Props> = ({
  initialJobPostSearchParams,
  initialJobPosts,
  totalHits,
  initialNextCursor,
  relatedPages,
}) => {
  const newestJobPostCreatedAt = initialJobPosts.filter((j) => !j.isSticky)[0]
    ?.createdAt;

  return (
    <>
      <div className="px-4">
        <h2 className="text-2xl font-bold">
          Search results
          {initialJobPostSearchParams.page === 1 && newestJobPostCreatedAt && (
            <span className="ml-4 text-base font-normal">
              Latest post about{" "}
              {timeSinceFormatter(new Date(newestJobPostCreatedAt), {
                addSuffix: true,
              })}
              .
            </span>
          )}
        </h2>
      </div>
      <JobPostsList
        jobPostSearchParams={initialJobPostSearchParams}
        jobPosts={initialJobPosts}
        initialNextCursor={initialNextCursor}
      />
      {/* Pagination */}
      {initialJobPosts.length > 0 && (
        <div className="mt-10 mb-20">
          <JobPostsPagination
            totalHits={totalHits}
            // from={initialJobPostSearchParams.from}
            size={initialJobPostSearchParams.size}
            currentPage={initialJobPostSearchParams.page}
          />
        </div>
      )}
      {/* Related pages */}
      {relatedPages?.length > 0 && (
        <div className="my-10 p-6 pt-4 border  rounded-md ">
          <h2 className="mb-3 text-xl font-bold">Related searches</h2>
          <div className="text-sm">
            <RelatedPages relatedPages={relatedPages} />
          </div>
        </div>
      )}
      {/* Join the newsletter */}
      <div className="my-10 p-6 pt-4 rounded-md bg-gray-50">
        <h2 className="mb-4 text-xl font-bold">
          Get <span className="">new jobs</span> in your inbox.
        </h2>
        <EmailSubscriptionForm defaultGroups={["69844943583053565"]} />
      </div>
      {/* Total hits */}
      {/* {totalHits > 0 && (
          <div className="my-6 p-6 bg-yellow-100">
            {totalHits} results found.
          </div>
        )} */}
    </>
  );
};

import React, { FC } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { classNames } from "@utils/classNames";
import { ActiveLink } from "@components/ActiveLink/ActiveLink";
import { useRouter } from "next/router";

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div className="hidden justify-center items-center pr-2 sm:flex">
      <button
        type="button"
        title="Previous"
        onClick={() => scrollPrev()}
        className={classNames(
          "flex justify-center items-center w-8 h-8 border rounded-full",
          isFirstItemVisible ? "opacity-0" : "opacity-100"
        )}
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div className="hidden justify-center items-center pl-2 sm:flex">
      <button
        type="button"
        title="Next"
        onClick={() => scrollNext()}
        className={classNames(
          "flex justify-center items-center w-8 h-8 border rounded-full",
          isLastItemVisible ? "opacity-0" : "opacity-100"
        )}
      >
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

const CN =
  "flex flex-col space-between content-between items-center whitespace-nowrap mx-0.5 px-2 py-2 text-xs font-medium bg-gray-100 rounded-full hover:bg-gray-200";
const activeCN = "text-white bg-[#3333ff] hover:bg-[#3333ff]";

const items = [
  {
    path: "/",
    label: "All jobs",
  },
  {
    path: "/full-stack-developer-jobs",
    label: "Full stack",
  },
  {
    path: "/front-end-developer-jobs",
    label: "Front end",
  },
  {
    path: "/junior-developer-jobs",
    label: "Junior",
  },
  {
    path: "/mid-level-developer-jobs",
    label: "Mid-weight",
  },
  {
    path: "/senior-developer-jobs",
    label: "Senior",
  },
  {
    path: "/north-america",
    label: "North America",
  },
  {
    path: "/south-america",
    label: "South America",
  },
  {
    path: "/europe",
    label: "Europe",
  },
  {
    path: "/africa",
    label: "Africa",
  },
  {
    path: "/asia",
    label: "Asia",
  },
  {
    path: "/oceania",
    label: "Oceania",
  },
];

interface Props {}

export const ListNavigation: FC<Props> = ({}) => {
  const router = useRouter();
  const selectedItem = items.find(({ path }) => path === router.asPath);
  const restOfItems = items.filter(({ path }) => path !== router.asPath);
  const combinedItems = [selectedItem, ...restOfItems].filter(
    (i) => i !== undefined
  );

  return (
    <div>
      <div className="py-2">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {combinedItems.map(({ path, label }) => (
            <ActiveLink
              key={path}
              href={path}
              className={CN}
              activeClassName={activeCN}
            >
              {label}
            </ActiveLink>
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
};

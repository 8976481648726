import { FC } from "react";
import { JobPostPreview } from "@components/JobPost/JobPostPreview";
import { JobPostSearchParams } from "@services/elasticsearch/utils/getJobPostSearchParamsFromServerQuery";
import {
  Remote,
  Compensation,
  Location,
  Organisation,
  JobPost,
} from "@prisma/client";
import { AssignedTag, NewTag } from "types/tags";

interface Props {
  jobPostSearchParams: JobPostSearchParams;
  jobPosts: (JobPost & {
    remote: Remote;
    locations: Location[];
    compensation: Compensation;
    sourcingOrganisation: Organisation;
    hiringOrganisation: Organisation;
    tags?: (AssignedTag & NewTag)[];
  })[];
  initialNextCursor?: string | string[];
}

export const JobPostsList: FC<Props> = ({ jobPosts }) => {
  return Array.isArray(jobPosts) && jobPosts.length > 0 ? (
    <ul className="my-4 border-t border-b overflow-hidden divide-y divide-gray-200 sm:border sm:rounded-md ">
      {jobPosts?.map((jobPost) => (
        <JobPostPreview
          key={jobPost.id}
          jobPost={jobPost}
          hasSize={true}
          hasOrganisationAbout={true}
          cN="px-2 pt-2 pb-4 text-xs sm:px-4 sm:pt-4 sm:pb-6"
        />
      ))}
    </ul>
  ) : (
    <div className="mx-auto mt-4 mb-10 text-center">No results.</div>
  );
};

import React, { FC } from "react";
import { classNames } from "@utils/classNames";

interface Props {
  heading: string;
  subHeading: string;
  style?: any;
  cn?: string;
  headingCn?: string;
  subHeadingCn?: string;
}

export const Hero: FC<Props> = ({
  heading,
  subHeading,
  style = {},
  cn,
  headingCn,
  subHeadingCn,
}) => {
  return (
    <div className={classNames("text-center", cn)}>
      <h1
        className="text-5xl tracking-tight font-black text-[#3333ff] sm:text-6xl md:text-7xl"
        style={style?.heading}
      >
        {heading}
      </h1>

      {/* <p
        className="mt-6 text-xl tracking-tight font-semiboldX text-[#3333ff] sm:mt-8 sm:text-3xl md:mt-10 md:text-xl"
        style={style?.subHeading}
      >
        {subHeading}
      </p> */}
    </div>
  );
};

import { FC, useEffect } from "react";
import {
  Compensation,
  JobPost,
  Organisation,
  Remote,
  Location,
} from "@prisma/client";
import { JobPostsSearchAndResults } from "@components/JobPosts/JobPostsSearchAndResults/JobPostsSearchAndResults";
import { Layout } from "@components/Layout/Layout";
import { JobPostSearchParams } from "@services/elasticsearch/utils/getJobPostSearchParamsFromServerQuery";
import { AnalyticsEvent } from "@utils/redis/analytics/AnalyticsEvent";
import { gtagEvent } from "@services/googleAnalytics";
import { mixpanelEvent } from "@services/mixpanel";
import { JobsInJSOrganisationJsonLd } from "@components/JsonLd/JobsInJSOrganisationJsonLd";
import { Hero } from "./Hero";
import { ListNavigation } from "@components/ListNavigation/ListNavigation";
import { SectionNavigation } from "@components/ListNavigation/SectionNavigation";
import Image from "next/image";
import { EmailSubscriptionForm } from "@components/EmailSubscriptionForm/EmailSubscriptionForm";
import { PostJobButton } from "@components/Layout/Header/PostJobButton";
import Link from "next/link";

export interface SeoPageProps {
  pageTitle: string;
  metaDescription?: string;
  heading?: string;
  subHeading?: string;
  jobPosts: (JobPost & {
    remote: Remote;
    locations: Location[];
    compensation: Compensation;
    sourcingOrganisation: Organisation;
    hiringOrganisation: Organisation;
  })[];
  totalHits: number;
  nextCursor: any;
  jobPostSearchParams: JobPostSearchParams;
  relatedPages: {
    path: string;
    pageTitle: string;
  }[];
  canonicalUrl?: string;
  hasRobotsNoIndex?: boolean;
}

export const SeoPage: FC<SeoPageProps> = ({
  pageTitle,
  metaDescription,
  heading,
  subHeading,
  jobPosts,
  totalHits,
  nextCursor,
  jobPostSearchParams,
  relatedPages,
  canonicalUrl,
  hasRobotsNoIndex,
}) => {
  useEffect(() => {
    // Google analytics: Record job post impressions.
    gtagEvent({
      name: "view_item_list",
      payload: {
        items: jobPosts.map((jobPost) => ({
          item_id: jobPost.id,
          item_name: jobPost.title,
        })),
      },
    });

    // Mixpanel: Record job post impressions.
    mixpanelEvent({
      name: AnalyticsEvent.JOB_POST_IMPRESSION,
      payload: {
        jobPosts: jobPosts.map((jobPost) => ({
          id: jobPost.id,
          title: jobPost.title,
        })),
      },
    });

    // Internal analytics: Record job post impressions.
    fetch("/api/analytics/collect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: AnalyticsEvent.JOB_POST_IMPRESSION,
        ids: jobPosts.map((jobPost) => jobPost.id),
      }),
    });
  }, []);

  return (
    <Layout
      pageTitle={pageTitle}
      metaDescription={metaDescription}
      canonicalUrl={canonicalUrl}
      hasRobotsNoIndex={hasRobotsNoIndex}
      hasSearch
    >
      <JobsInJSOrganisationJsonLd />
      <div className="max-w-4xl m-auto">
        <div className="px-2 py-8 sm:p-8 bg-gray-50 rounded-3xl">
          <Hero heading={heading} subHeading={subHeading} />
          <Link
            href="/about"
            className="flex flex-col justify-center items-center mt-6 text-center text-gray-600"
          >
            <Image
              src="/images/michal-profile-photo-1.jpg"
              width={64}
              height={64}
              className="mb-2 rounded-full"
              alt="Michal's profile photo"
            />
            <div className=""></div>
            <span className="block">Hey, I'm Michal 👋</span>
            <span className="block">
              I find and curate JavaScript jobs at exciting startups.
            </span>
            <span className="mt-2 block space-x-4 text-sm">
              <Link
                href="/job-posts/create"
                className="underline"
              >
                Post job
              </Link>
              <Link href="/about" className="underline">
                Contact me
              </Link>
              <Link href="/sponsors" className="underline">
                Become a sponsor
              </Link>
            </span>
          </Link>
          <div className="max-w-sm mt-6 m-auto text-center text-sm text-gray-600">
            <div className="mb-2 font-normal">Don't miss the best jobs</div>
            <EmailSubscriptionForm defaultGroups={["69844943583053565"]} />
          </div>
        </div>
        {/* <div className="-mx-96 mt-4 mb-10">
          <SectionNavigation />
        </div> */}
        <div className="mt-10 mb-10">
          <ListNavigation />
        </div>
        <JobPostsSearchAndResults
          initialJobPostSearchParams={jobPostSearchParams}
          initialJobPosts={jobPosts}
          totalHits={totalHits}
          initialNextCursor={nextCursor}
          relatedPages={relatedPages}
        />
      </div>
    </Layout>
  );
};

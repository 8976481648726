import { FC } from "react";

interface Props {}

export const JobsInJSOrganisationJsonLd: FC<Props> = () => {
  // Put it all together.
  const jsonLD = {
    "@context": "http://www.schema.org",
    "@type": "Organization",
    name: "Jobs in JS",
    url: "https://jobsinjs.com/",
    logo: "https://jobsinjs.com/jobsinjs-logo.svg",
    sameAs: ["https://twitter.com/jobsinjs", ""],
  };
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLD),
      }}
    />
  );
};

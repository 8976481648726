import { OptionalBadge } from "@components/Badges/OptionalBadge";
import { RequiredBadge } from "@components/Badges/RequiredBadge";
import { ErrorMessage } from "@hookform/error-message";
import { classNames } from "@utils/classNames";
import get from "lodash/get";
import { UseFormRegister } from "react-hook-form";
import {
  defaultLabelCN,
  defaultInputCN,
  defaultErrorMessageCN,
  defaultInputErrorStateCN,
} from "./Styles";

interface Props {
  label?: string;
  hasOptionalBadge?: boolean;
  hasRequiredBadge?: boolean;
  path: string;
  placeholder?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  registerOptions?: any;
  errors?: any;
  type?: HTMLInputElement["type"];
  labelCN?: string;
  inputCN?: string;
  inputErrorStateCN?: string;
  errorMessageCN?: string;
  [key: string]: any;
}

export const Input = ({
  label,
  hasOptionalBadge,
  hasRequiredBadge,
  path,
  placeholder,
  disabled = false,
  register,
  registerOptions = {},
  type = "text",
  errors = {},
  labelCN = defaultLabelCN,
  inputCN = defaultInputCN,
  inputErrorStateCN = defaultInputErrorStateCN,
  errorMessageCN = defaultErrorMessageCN,
  ...rest
}: Props) => {
  return (
    <>
      {label && (
        <label htmlFor={path} className={labelCN}>
          {label} {hasOptionalBadge && <OptionalBadge />}{" "}
          {hasRequiredBadge && <RequiredBadge />}
        </label>
      )}
      <input
        type={type}
        id={path}
        placeholder={placeholder}
        disabled={disabled}
        {...register(path, registerOptions)}
        autoComplete={path}
        className={classNames(
          get(errors, path) ? inputErrorStateCN : "",
          inputCN
        )}
        {...rest}
      />
      <ErrorMessage
        errors={errors}
        name={path}
        render={({ message }) => <p className={errorMessageCN}>{message}</p>}
      />
    </>
  );
};
